import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames/bind'
import styles from '../styles/components/footer.module.scss'

import SpinningFinch from './spinningFinch'

import LinkedinIcon from '../images/logos/icon-linkedin.svg'
import FacebookIcon from '../images/logos/icon-facebook.svg'
import TwitterIcon from '../images/logos/icon-twitter.svg'
import InstagramIcon from '../images/logos/icon-instagram.svg'

let cx = classnames.bind(styles)

const Footer = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      backgroundColor: '#fff0e0',
    }
  }
  toggleBackgroundColor() {
    if (this.state.backgroundColor === '#fff0e0') {
      this.setState({ backgroundColor: '#ffe5d0' })
    } else {
      this.setState({ backgroundColor: '#fff0e0' })
    }
  }

  render() {
    let backgroundClass = cx('background', {
      fullHeight: this.props.isFullHeight,
    })

    const whiteBackground = this.props.whiteBackground

    let letsBuildLink = cx('letsBuildLink', { letsBuildRed: whiteBackground })
    let socialLinks = cx({
      socialLinksRed: whiteBackground,
      socialLinks: !whiteBackground,
    })

    if (whiteBackground) {
      this.state.backgroundColor = '#ffffff'
    }
    return (
      <div
        className={backgroundClass}
        style={{ backgroundColor: this.state.backgroundColor }}
      >
        <div className={styles.topContainer}>
          {!whiteBackground && (
            <div className={styles.circleIconGroup}>
              <SpinningFinch />
            </div>
          )}
          <div
            className={styles.linkContainer}
            onMouseEnter={this.toggleBackgroundColor.bind(this)}
            onMouseLeave={this.toggleBackgroundColor.bind(this)}
          >
            <Link to="/contact-form/" className={letsBuildLink}>
              Let's build something awesome together!
            </Link>
          </div>
        </div>
        <div className={styles.bottomText}>
          <div className={styles.emailAndCopyright}>
            <span id={styles.email}>studio@15finches.com</span>
            <span>
              &copy; {new Date().getFullYear()} Weston Baker Creative Group
            </span>
          </div>
          <span className={socialLinks}>
            <a
              href="https://www.linkedin.com/company/15finches/"
              target="_blank"
              rel="noopener noreferrer"
              name="linkedinIcon"
            >
              <LinkedinIcon />
            </a>
            <a
              href="https://www.facebook.com/15finches"
              target="_blank"
              rel="noopener noreferrer"
              name="facebookIcon"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/15finches"
              target="_blank"
              rel="noopener noreferrer"
              name="twitterIcon"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.instagram.com/15finches/"
              target="_blank"
              rel="noopener noreferrer"
              name="instagramIcon"
            >
              <InstagramIcon />
            </a>
          </span>
        </div>
      </div>
    )
  }
}

export default Footer
