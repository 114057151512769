import React from 'react'
import styles from '../styles/components/email-collect.module.scss'
import classNames from 'classnames/bind'
import md5 from 'md5'
import axios from 'axios'

import RightArrowBlue from '../images/right-arrow-blue.svg'

const cx = classNames.bind(styles)

class EmailCollect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      firstNameError: false,
      emailError: false,

      submitted: false,
    }
    this.addEmail = this.addEmail.bind(this)
  }

  addEmail() {
    const LIST_ID = process.env.GATSBY_MAILCHIMP_LIST_ID
    const API_KEY = process.env.GATSBY_MAILCHIMP_API_KEY

    const data = {
      email_address: this.state.email,
      status: 'subscribed',
      status_if_new: 'subscribed',
      merge_fields: {
        FNAME: this.state.firstName,
        LNAME: this.state.lastName,
      },
    }

    const config = {
      headers: { Authorization: `apikey ${API_KEY}` },
    }

    const subscriber_hash = md5(this.state.email.toLowerCase())

    const url = `https://wbcg-cors-anywhere.herokuapp.com/https://us14.api.mailchimp.com/3.0/lists/${LIST_ID}/members/${subscriber_hash}`

    axios.put(url, data, config).then(function(response) {
      // console.log(response)
    })

    this.setState(() => ({ submitted: true }))
  }

  updateState = e => {
    const name = e.target.name
    const value = e.target.value
    if (!this.state.submitted) {
      this.setState(() => ({
        [name]: value,
        emailError: false,
        firstNameError: false,
      }))
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.state.submitted) {
      const emailError = !this.state.email.match(/.+@.+\..+/)
      const firstNameError = this.state.firstName.length === 0
      if (emailError || firstNameError) {
        this.setState(() => ({
          emailError: emailError,
          firstNameError: firstNameError,
        }))
      } else {
        this.addEmail()
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={cx('notSubmitted', { hidden: this.state.submitted })}>
          <div className={styles.titleText}>
            Stay updated with the latest news curated by our designers and
            engineers
          </div>
          <form className={styles.formContainer} onSubmit={this.handleSubmit}>
            <div className={styles.inputContainer}>
              <input
                className={styles.inputField}
                spellCheck="false"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this.updateState}
              />
              <div
                className={cx('errorMessage', {
                  shown: this.state.firstNameError,
                })}
              >
                Please input your name.
              </div>
            </div>
            <div className={styles.inputContainer}>
              <input
                className={styles.inputField}
                spellCheck="false"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.updateState}
              />
            </div>
            <div className={styles.inputContainer}>
              <input
                className={styles.inputField}
                spellCheck="false"
                type="email"
                name="email"
                placeholder="email@email.com"
                value={this.state.email}
                onChange={this.updateState}
              />
              <div
                className={cx('errorMessage', { shown: this.state.emailError })}
              >
                Please input a valid email address
              </div>
            </div>

            <button type="submit" className={styles.submitButton}>
              <div className={styles.submitText}>Submit</div>
              <RightArrowBlue alt="arrow" />
            </button>
          </form>
        </div>
        <div className={cx('submitted', { shown: this.state.submitted })}>
          <div className={styles.submitText}>Thank you for signing up!</div>
        </div>
      </div>
    )
  }
}

export default EmailCollect
